import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Examples`}</h2>
    <p><strong parentName="p">{`Named queries`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query GetPerson($id: ID!) {
  person(id: $id) {
    name
    age
  }
}
`}</code></pre>
    <p><strong parentName="p">{`Mutations`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  addPerson(input: { name: "Alice", age: 30 }) {
    id
    name
    age
  }
}
`}</code></pre>
    <p><strong parentName="p">{`Interfaces`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`interface Animal {
  name: String!
  makeSound: String!
}

type Dog implements Animal {
  name: String!
  makeSound: String!
  breed: String!
}
`}</code></pre>
    <p><strong parentName="p">{`Types`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`input PersonInput {
  name: String!
  age: Int!
}

type Mutation {
  addPerson(input: PersonInput!): Person!
}

type Person {
  id: ID!
  name: String!
  age: Int!
}
`}</code></pre>
    <p><strong parentName="p">{`Enums`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`enum UserRole {
  ADMIN
  USER
  GUEST
}

type User {
  role: UserRole!
  username: String!
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      